import React from "react";
import NAVIGATION from "components/navigation/navigation";
import FOOTER from "components/footer/footer";
import PREVIEW from "views/staff/components/preview";
import find_asset_index from "functions/contentful/find_asset_index";


export type State = {
  contentful_data?: any,
  staff_data?: any
};

class Staff extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      contentful_data: undefined,
      staff_data: []
    };
  }

  componentDidMount() {
    document.title = "DGG - Our Staff";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ content_type: "staffPage" })
    };
    fetch(process["env"]["REACT_APP_API_ROOT"] + "/contentful", requestOptions)
      .then(res => res.json())
      .then(data => {
        this.setState({ contentful_data: data })
        let staff_array = [];
        for (
          let i = 0;
          i < data["items"][0]["fields"]["staffProfiles"].length;
          i++
        ) {
          let staff_index = find_asset_index(
            data["items"][0]["fields"]["staffProfiles"][i]["sys"]["id"],
            data["includes"]["Entry"]
          )
          staff_array.push(
            data["includes"]["Entry"][
            staff_index === false ? 0 : staff_index
            ]
          );
        }
        this.setState({ staff_data: staff_array })

      })
      .catch(console.log);
  }

  render() {
    return (
      <>
        <NAVIGATION active_tab="staff" />
        {this["state"]["contentful_data"] !== undefined && (
          <>
            <section className="narrow-section">
              <div className="staff-previews-container">
                <h1>Our Team</h1>
                {this["state"]["staff_data"].length > 0 &&
                  this["state"]["staff_data"].map((staff, index) => (
                    <PREVIEW
                      content={staff}
                      key={index}
                      assets={this["state"]["contentful_data"]["includes"]}
                    />
                  ))}
              </div>
            </section>
          </>
        )}
        <FOOTER />
      </>
    );
  }
}


export default Staff;

import React from "react";
import { Link } from "react-router-dom";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import find_asset_index from "functions/contentful/find_asset_index";



export type Props = {
  assets?: any,
  image?: any,
  title?: any,
  preview?: any
};

export type State = {
  image_data?: any
};

class Insights_Preview extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      image_data: undefined
    };
  }

  componentDidMount() {
    let asset_index = find_asset_index(this["props"]["image"]["sys"]["id"], this["props"]["assets"]);
    this.setState({
      image_data: this["props"]["assets"][
        asset_index === false ? 0 : asset_index
      ]
    });
  }

  render() {
    return (
      <>
        <Link
          to={"/insights/" + this["props"]["title"].toLowerCase().replace(/ /g, "_")}
          className="item card"
        >
          <div className="preview-image">
            {this["state"]["image_data"] !== undefined && (
              <img src={this["state"]["image_data"]["fields"]["file"]["url"]} alt="" />
            )}
          </div>
          <div className="preview-content">
            <h3>{this["props"]["title"]}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: documentToHtmlString(this["props"]["preview"])
              }}
            ></div>
          </div>
        </Link>
      </>
    );
  }
}


export default Insights_Preview

import React from "react";
import NAVIGATION from "components/navigation/navigation";
import CONTACT_FORM from "components/contact/contact_form";
import BANNER from "views/home/components/banner";
import ABOUT_US_SECTION from "views/home/components/about_us_section";
import OUR_SERVICES_SECTION from "views/home/components/our_services_section";
import INSIGHTS_SECTION from "views/home/components/insights_section";
import FOOTER from "components/footer/footer";

export type State = {
  contentful_data?: any
};

class Home extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      contentful_data: undefined
    };
  }

  componentDidMount() {
    document.title = "Digital Gaming Group";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ content_type: "homePage" })
    };
    fetch(process["env"]["REACT_APP_API_ROOT"] + "/contentful", requestOptions)
      .then(res => res.json())
      .then(data => {
        this.setState({ contentful_data: data })
      })
      .catch(console.log);
  }

  render() {
    return (
      <>
        <NAVIGATION active_tab="home" />
        {this["state"]["contentful_data"] !== undefined &&
          <>
            <BANNER
              background={this["state"]["contentful_data"]["items"][0]["fields"]["bannerBackground"]
              }
              image={this["state"]["contentful_data"]["items"][0]["fields"]["bannerImage"]
              }
              assets={
                this["state"]["contentful_data"]["includes"]
              }
            />
            <div className="slant accent"> </div>
            <div className="slant home-banner"> </div>
            <section className="wide-section padded home">
              <ABOUT_US_SECTION
                body={
                  this["state"]["contentful_data"]["items"][0]["fields"]["aboutUsBody"]
                }
                assets={
                  this["state"]["contentful_data"]["includes"]
                }
              />
            </section>
            <OUR_SERVICES_SECTION
              header={
                this["state"]["contentful_data"]["items"][0]["fields"]["servicesHeader"]
              }
              services={
                this["state"]["contentful_data"]["items"][0]["fields"]["services"]
              }
              assets={
                this["state"]["contentful_data"]["includes"]
              }
            />
            <INSIGHTS_SECTION
              header={
                this["state"]["contentful_data"]["items"][0]["fields"]["insightsHeader"]
              }
              insights={
                this["state"]["contentful_data"]["items"][0]["fields"]["featuredInsights"]
              }
              assets={
                this["state"]["contentful_data"]["includes"]
              }
            />
          </>
        }
        <section className="wide-section contact_us_section padded">
          <h1 className="section-header">Contact Us</h1>
          <CONTACT_FORM />
        </section>
        <div className="slant accent footer"> </div>

        <FOOTER />
      </>
    );
  }
}


export default Home;

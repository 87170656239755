import React from "react";
import FORM_INPUTS from "components/contact/components/form_inputs";

export type State = {
  contentful_data?: any,
  message_sender_loading?: any,
  confirmation_message?: any,
  form_data?: any,
  form_errors?: any,

};

class Contact_Form extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      contentful_data: undefined,
      message_sender_loading: false,
      confirmation_message: "empty",
      form_data: {
        name: "",
        email_address: "",
        message: ""
      },
      form_errors: {
        name: "",
        email_address: "",
        message: ""
      },
    };
    this.set_form_data = this.set_form_data.bind(this);
    this.set_form_errors = this.set_form_errors.bind(this);
    this.send_form = this.send_form.bind(this);
  }

  send_form() {
    let form_validation_object = this.validate_form();
    if (
      !form_validation_object["name"] &&
      !form_validation_object["email_address"] &&
      !form_validation_object["message"]
    ) {
      this.setState({ message_sender_loading: true })

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(this["state"]["form_data"])
      };
      fetch(
        process["env"]["REACT_APP_API_ROOT"] + "/contact/send_form",
        requestOptions
      )
        .then(res => res.json())
        .then(data => {
          this.setState({ message_sender_loading: false })
          if (data["error"]) {
            this.setState({ confirmation_message: "error" })

          } else {
            this.setState({ confirmation_message: "success" })
          }
          this.setState({
            form_data: {
              name: "",
              email_address: "",
              message: ""
            }
          })

        })
        .catch(console.log);
    }
  }

  validate_form() {
    let form_validation_object = {
      ...this.state.form_errors,
      name: this.state.form_data["name"].length === 0 ? true : false,
      email_address: this.is_email_valid(),
      message: this.state.form_data["message"].length === 0 ? true : false
    };
    this.setState({ form_errors: form_validation_object })
    return form_validation_object;
  }

  is_email_valid() {
    var email_pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    return !email_pattern.test(this.state.form_data["email_address"]);
  }

  set_form_data(new_form_data) {
    this.setState({ form_data: new_form_data })
  }
  set_form_errors(new_form_errors) {
    this.setState({ form_errors: new_form_errors })
  }

  componentDidMount() {
  }

  render() {
    const confirmation_messages = {
      success: (
        <>
          <h3>Your message has been sent!</h3>
          <p>
            Thank you for contacting Digital Gaming Group. Please allow 2 business
            days for a response.
          </p>
        </>
      ),
      error: (
        <>
          <h3>There's been an error.</h3>
          <p>Please try again or email contact@digitalgaminggroup.com</p>
        </>
      ),
      empty: ""
    };

    const form_error_messages = {
      name: "Please enter a name",
      email_address: "Please enter a valid email address",
      message: "Please enter a message"
    };
    return (
      <>

        <div className="contact-form-container">
          {this.state.confirmation_message !== "empty" &&
            <div className="confirmation desktop">
              {confirmation_messages[this.state.confirmation_message]}
            </div>
          }
          <FORM_INPUTS
            form_data={this.state.form_data}
            set_form_data={this.set_form_data}
            set_form_errors={this.set_form_errors}
            form_errors={this.state.form_errors}
            form_error_messages={form_error_messages}
            send_form={this.send_form}
            message_sender_loading={this.state.message_sender_loading}
          />

          {this.state.confirmation_message !== "empty" &&
            <div className="confirmation mobile">
              {confirmation_messages[this.state.confirmation_message]}
            </div>
          }

        </div>
      </>
    );
  }
}


export default Contact_Form;

import React from "react";
import find_asset_index from "functions/contentful/find_asset_index";

export type Props = {
  assets?: any,
  image?: any,
  height?: any
};

export type State = {
  image_data?: any
};

class Banner extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      image_data: undefined
    };
  }

  componentDidMount() {
    if (this["props"]["assets"] !== undefined && this["props"]["image"]["sys"] !== undefined) {
      let asset_index = find_asset_index(
        this["props"]["image"]["sys"]["id"],
        this["props"]["assets"]["Asset"]
      );
      this.setState({
        image_data: this["props"]["assets"]["Asset"][
          asset_index === false ? 0 : asset_index
        ]
      })
    }
  }

  render() {
    return (
      <>
        {this["state"]["image_data"] !== undefined && (
          <div
            className={
              "banner " + (this["props"]["height"] === undefined ? "" : this["props"]["height"])
            }
          >
            <img src={this["state"]["image_data"]["fields"]["file"]["url"]} alt="banner" />
          </div>
        )}
      </>
    );
  }
}


export default Banner;

import React from "react";
import { Link } from "react-router-dom";
import find_asset_index from "functions/contentful/find_asset_index";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import dateFormat from "dateformat";


export type Props = {
  preview?: any,
  assets?: any,
  image_first?: boolean
};

export type State = {
  image_data?: any
};

class Insight extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      image_data: undefined
    };
  }

  componentDidMount() {
    if (this["props"]["preview"] !== undefined) {
      let image_index = find_asset_index(
        this["props"]["preview"]["fields"]["previewImage"].sys.id,
        this["props"]["assets"]
      );
      this.setState({
        image_data: this["props"]["assets"][
          image_index === false ? 0 : image_index
        ]
      })
    }
  }

  render() {
    let image = <div> </div>;
    if (this["state"]["image_data"] !== undefined) {
      image = <Link
        to={
          "/insights/" +
          this["props"]["preview"]["fields"]["title"].toLowerCase().replace(/ /g, "_")
        } className="insights-preview-image">
        <img src={this["state"]["image_data"]["fields"]["file"]["url"]} alt="" />
      </Link>
    }
    const content = <div className="insights-preview-content">
      <Link
        to={
          "/insights/" +
          this["props"]["preview"]["fields"]["title"].toLowerCase().replace(/ /g, "_")
        }
      >
        <h3 className="title">{this["props"]["preview"]["fields"]["title"]}</h3>
      </Link>
      <p className="publish-date">
        {dateFormat(this["props"]["preview"]["fields"]["publishDate"], "mmmm d, yyyy")}
      </p>
      <p className="author-text">
        <b>Written by:</b> {this["props"]["preview"]["fields"]["author"]}
      </p>
      <div
        dangerouslySetInnerHTML={{
          __html: documentToHtmlString(
            this["props"]["preview"]["fields"]["previewText"]
          )
        }}
        className="preview-text"
      ></div>
      <Link
        to={
          "/insights/" +
          this["props"]["preview"]["fields"]["title"].toLowerCase().replace(/ /g, "_")
        }
      >
        <button className="secondary-button">Read more</button>
      </Link>
    </div>

    return (
      <>
        <div className={"insights-preview-container " + (this["props"]["image_first"] ? "" : "flip")}>
          {this["props"]["image_first"] && image}
          {this["props"]["image_first"] && content}
          {!this["props"]["image_first"] && content}
          {!this["props"]["image_first"] && image}
        </div>
      </>
    );
  }
}


export default Insight;

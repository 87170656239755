import React from "react";
import { Link } from "react-router-dom";
import BANNER from "components/banner/banner";
import find_asset_index from "functions/contentful/find_asset_index";


export type Props = {
  image?: any,
  background?: any,
  assets?: any
};

export type State = {
  image_data?: any,
  loaded?: any
};

class Home extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      image_data: {},
      loaded: false
    };
  }

  componentDidMount() {
    if (this["props"]["assets"] !== undefined && this["props"]["image"]["sys"] !== undefined) {
      let asset_index = find_asset_index(
        this["props"]["image"]["sys"]["id"],
        this["props"]["assets"]["Asset"]
      );
      this.setState({
        image_data: this["props"]["assets"]["Asset"][
          asset_index === false ? 0 : asset_index
        ], loaded: true
      })
    }
  }

  render() {
    return (
      <>
        <BANNER
          image={this.props.background}
          assets={this.props.assets}
          height="tall"
        />
        <div className="dgg-banner-container">
          <div className="copy">
            <h1>Digital Gaming Group</h1>
            <h2>Helping lotteries to modernize and grow.</h2>
            <Link to="/contact" >
              <button className="accent-button">Contact us</button>
            </Link>
          </div>
          {this.state.loaded &&
            <img src={this["state"]["image_data"]["fields"]["file"]["url"]} alt="" className="banner-image" />
          }
        </div>

      </>
    );
  }
}


export default Home;

import React from "react";
import loader from "assets/images/loader.svg";

function Form_Inputs(props) {
  return (
    <>
      <div className="form">
        <div className="name-input-container">

          <label htmlFor="name-input">
            <p>Name:<span className="required"> *</span></p>
            {props.form_errors["name"] && (
              <span>{props.form_error_messages["name"]}</span>
            )}
          </label>

          <input
            type="text"
            id="name-input"
            value={props.form_data["name"]}
            onChange={e => {
              props.set_form_data({
                ...props.form_data,
                name: e["target"]["value"]
              });
              props.set_form_errors({
                ...props.form_errors,
                name: false
              });
            }}
          />

        </div>
        <label htmlFor="email-input">
          <p>Email Address:<span className="required"> *</span></p>
          {props.form_errors["email_address"] && (
            <span>{props.form_error_messages["email_address"]}</span>
          )}
        </label>

        <input
          type="text"
          id="email-input"
          value={props.form_data["email_address"]}
          className="email-input"
          onChange={e => {
            props.set_form_data({
              ...props.form_data,
              email_address: e["target"]["value"]
            });
            props.set_form_errors({
              ...props.form_errors,
              email_address: false
            });
          }}
        />

        <label htmlFor="message-input">
          <p>Message:<span className="required"> *</span></p>
          {props.form_errors["message"] && (
            <span>{props.form_error_messages["message"]}</span>
          )}
        </label>

        <textarea
          className="message-input"
          id="message-input"
          value={props.form_data["message"]}
          onChange={e => {
            props.set_form_data({
              ...props.form_data,
              message: e["target"]["value"]
            });
            props.set_form_errors({ ...props.form_errors, message: false });
          }}
        />
        <p className="required-fields"><span className="required">* </span>Required fields</p>
        <div className="send-message-container">
          <button className="primary-button" onClick={props.send_form}>
            Send
          </button>
          {props.message_sender_loading && (
            <img
              src={loader}
              className="loader"
              alt="loading"
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Form_Inputs;

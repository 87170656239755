import React from "react";
import { Link } from "react-router-dom";
import find_asset_index from "functions/contentful/find_asset_index";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";


export type Props = {
  header?: any,
  assets?: any,
  services?: any
};

export type State = {
  services_data?: any
};

class Our_Services_Section extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      services_data: []
    };
  }

  componentDidMount() {
    if (this["props"]["assets"] !== undefined) {
      let service_array = [];
      for (let i = 0; i < this["props"]["services"].length; i++) {
        let service_index = find_asset_index(
          this["props"]["services"][i]["sys"]["id"],
          this["props"]["assets"]["Entry"]
        )
        service_array.push(
          this["props"]["assets"]["Entry"][
          service_index === false ? 0 : service_index
          ]
        );
      }
      this.setState({ services_data: service_array })
    }
  }

  render() {
    return (
      <>
        <div className="slant services top"> </div>
        <div className="services-section ">
          <div className="wide-section">
            <h1 className="section-header">{this["props"]["header"]}</h1>

            <div className="services-container">
              {this["state"]["services_data"].length > 0 &&
                this["state"]["services_data"].map((service, index) => (
                  <div

                    className="item card"
                    key={index}
                  >
                    <h3>{service["fields"]["title"]}</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: documentToHtmlString(service["fields"]["blurb"])
                      }}
                      className="preview"
                    ></div>
                  </div>
                ))}
            </div>
            {this["state"]["services_data"].length > 0 && (
              <Link to="/services" className="view-all-button button-link">
                <button className="primary-button">Learn more</button>
              </Link>
            )}
          </div>
        </div>
        <div className="slant services bottom"> </div>

      </>
    );
  }
}


export default Our_Services_Section;

import React from "react";
import NAVIGATION from "components/navigation/navigation";
import FOOTER from "components/footer/footer";
import SERVICE from "views/services/components/service";
import find_asset_index from "functions/contentful/find_asset_index";

export type Props = {
};


export type State = {
  contentful_data?: any,
  services_data?: any,
  tab_index_open?: any,
  url_param?: any
};

class Services extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      contentful_data: undefined,
      services_data: [],
      tab_index_open: 0,
      url_param: ""
    };
    this.switch_tabs = this.switch_tabs.bind(this);

  }

  componentDidMount() {
    this.get_service_data()
  }

  componentDidUpdate() {
    if (this["props"]["match"]["params"]["title"] !== this["state"]["url_param"]
    ) {
      this.get_service_data()
    }

  }

  get_service_data() {
    this.setState({ url_param: this["props"]["match"]["params"]["title"] })
    document.title = "DGG - Our Services";
    let title_parameter = this["props"]["match"]["params"]["title"];
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ content_type: "servicesPage" })
    };
    fetch(process["env"]["REACT_APP_API_ROOT"] + "/contentful", requestOptions)
      .then(res => res.json())
      .then(data => {
        this.setState({ contentful_data: data })
        let service_array = [];
        for (
          let i = 0;
          i < data["items"][0]["fields"]["services"].length;
          i++
        ) {
          let service_index = find_asset_index(
            data["items"][0]["fields"]["services"][i]["sys"]["id"],
            data["includes"]["Entry"]
          )
          let service_object =
            data["includes"]["Entry"][
            service_index === false ? 0 : service_index
            ];
          if (
            service_object["fields"]["title"]
              .toLowerCase()
              .replace(/ /g, "_") === title_parameter
          ) {
            console.log(i)
            this.setState({ tab_index_open: i })
          }
          service_array.push(service_object);
        }
        this.setState({ services_data: service_array })
      })
      .catch(console.log);
  }

  switch_tabs(tab_index, title) {
    this.setState({ tab_index_open: tab_index })
    this["props"]["history"].push(
      "/services/" + title.toLowerCase().replace(/ /g, "_")
    );
  }

  render() {
    return (
      <>
        <NAVIGATION active_tab="services" />
        <div className="wide-section services">
          <div className="services-page-content">
            <h1>Services</h1>

            {this["state"]["contentful_data"] !== undefined && this["state"]["services_data"].length > 0 && (
              this["state"]["services_data"].map((insight, index) => (
                <SERVICE
                  preview={insight}
                  key={index}
                  assets={this["state"]["contentful_data"]["includes"]["Asset"]}
                  image_first={index % 2 === 1 ? true : false}
                />
              )
              ))}

            <p></p>
          </div>
        </div>
        <FOOTER />
      </>
    );
  }
}


export default Services;

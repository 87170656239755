import React from "react";
import { Link } from "react-router-dom";
import find_asset_index from "functions/contentful/find_asset_index";
import INSIGHTS_PREVIEW from "../../../components/insights/insights_preview";


export type Props = {
  insights?: any,
  assets?: any,
  header?: any
};

export type State = {
  insights_data?: any
};

class Insights_Section extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      insights_data: []
    };
  }

  componentDidMount() {
    if (this["props"]["assets"] !== undefined) {
      let insight_array = [];
      for (let i = 0; i < this["props"]["insights"].length; i++) {
        let insight_index = find_asset_index(
          this["props"]["insights"][i]["sys"]["id"],
          this["props"]["assets"]["Entry"]
        );
        insight_array.push(
          this["props"]["assets"]["Entry"][
          insight_index === false ? 0 : insight_index
          ]
        );
      }
      this.setState({ insights_data: insight_array })
    }
  }

  render() {
    return (
      <>
        <section className="insights-preview-section">
          <section className="wide-section">
            <h1 className="section-header">{this["props"]["header"]}</h1>
            <div className="insights">
              {this["state"]["insights_data"].length > 0 &&
                this["state"]["insights_data"].map((insight, index) => (
                  <INSIGHTS_PREVIEW
                    key={index}
                    title={insight["fields"]["title"]}
                    preview={insight["fields"]["previewText"]}
                    image={insight["fields"]["previewImage"]}
                    assets={this["props"]["assets"]["Asset"]}
                  />
                ))}
            </div>
            {this["state"]["insights_data"].length > 0 && (
              <Link to="/insights" className="view-all-button button-link">
                <button className="primary-button">Discover more</button>
              </Link>
            )}
          </section>
        </section>
      </>
    );
  }
}


export default Insights_Section;

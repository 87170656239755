import React from "react";
import { Link } from "react-router-dom";
import logo from "assets/images/dgg-logo.svg";


function Desktop(props) {
  return (
    <>
      <div className="desktop-navbar" style={{ opacity: "100%" }}>
        <Link to="/">
          <img
            src={logo}
            className="dgg-logo"
            alt="Digital Gaming Group logo"
          />
        </Link>
        <div className="item-container">
          <Link to="/services">
            <div className={"item " + (props.active_tab === "services" ? "active" : "")}>Services</div>
            <div className={"underline" + (props.active_tab === "services" ? " active" : "")}></div>
          </Link>
          <Link to="/insights">
            <div className={"item " + (props.active_tab === "insights" ? "active" : "")}>Insights</div>
            <div className={"underline" + (props.active_tab === "insights" ? " active" : "")}></div>
          </Link>
          <Link to="/staff">
            <div className={"item " + (props.active_tab === "staff" ? "active" : "")}>Team</div>
            <div className={"underline" + (props.active_tab === "staff" ? " active" : "")}></div>
          </Link>
          <Link to="/contact">
            <div className={"item " + (props.active_tab === "contact" ? "active" : "")}>Contact</div>
            <div className={"underline" + (props.active_tab === "contact" ? " active" : "")}></div>

          </Link>
        </div>
      </div>
    </>
  );
}

export default Desktop;

import React from "react";
import ReactDOMServer from "react-dom/server";
import render_content_section from "functions/contentful/render_content_section";
import find_asset_index from "functions/contentful/find_asset_index";

function render_column_section(
  column_section_array,
  width_percentages,
  assets
) {
  let column_content = "";
  let width_array = [];
  if (width_percentages !== undefined) {
    width_array = width_percentages.split(" ");
  } else {
    for (let i = 0; i < column_section_array.length; i++) {
      width_array.push((100 / column_section_array.length).toString());
    }
  }

  for (let i = 0; i < column_section_array.length; i++) {
    let content_section_object =
      assets["Entry"][
        find_asset_index(column_section_array[i]["sys"]["id"], assets["Entry"])
      ];
    column_content += ReactDOMServer.renderToStaticMarkup(
      <div
        dangerouslySetInnerHTML={{
          __html: render_content_section(
            content_section_object["fields"]["body"],
            assets
          )
        }}
        className="column"
        style={{width: width_array[i] + "%"}}
      ></div>
    );
  }
  return column_content;
}

export default render_column_section;

import React from "react";
import { Link } from "react-router-dom";
import NAVIGATION from "components/navigation/navigation";
import FOOTER from "components/footer/footer";
import GO_BACK from "components/navigation/go_back/go_back";
import INSIGHTS_PREVIEW from "components/insights/insights_preview";
import dateFormat from "dateformat";
import render_content_section from "functions/contentful/render_content_section";
import render_column_section from "functions/contentful/render_column_section";
import find_asset_index from "functions/contentful/find_asset_index";


export type Props = {
};

export type State = {
  contentful_data?: any,
  content?: any,
  insights_data?: any,
  url_param?: any

};

class Post extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      contentful_data: undefined,
      content: [],
      insights_data: [],
      url_param: undefined
    };
  }

  componentDidMount() {
    this.get_post_data()
  }

  componentDidUpdate() {
    if (this["props"]["match"]["params"]["name"] !== this["state"]["url_param"]) {
      this.get_post_data()
    }
  }

  get_post_data() {
    this.setState({ contentful_data: undefined, content: undefined, insights_data: [], url_param: this["props"]["match"]["params"]["name"] })
    window.scrollTo(0, 0);
    fetch(
      process["env"]["REACT_APP_API_ROOT"] +
      "/contentful/get_insight/" +
      this["props"]["match"]["params"]["name"]
    )
      .then(res => res.json())
      .then(data => {
        document.title = data["items"][0]["fields"]["title"];
        let content_array = [];
        for (let i = 0; i < data["items"][0]["fields"]["body"].length; i++) {
          let content_index = find_asset_index(
            data["items"][0]["fields"]["body"][i]["sys"]["id"],
            data["includes"]["Entry"]
          );
          content_array.push(
            data["includes"]["Entry"][
            content_index === false ? 0 : content_index
            ]
          );
        }
        this.setState({ content: content_array, contentful_data: data })
        let insight_array = [];
        for (
          let i = 0;
          i < data["items"][0]["fields"]["featuredInsights"].length;
          i++
        ) {
          let insight_index = find_asset_index(
            data["items"][0]["fields"]["featuredInsights"][i]["sys"]["id"],
            data["includes"]["Entry"]
          )
          insight_array.push(
            data["includes"]["Entry"][
            insight_index === false ? 0 : insight_index
            ]
          );
        }
        this.setState({ insights_data: insight_array })
      })
      .catch(console.log);
  }

  render() {
    return (
      <>
        <NAVIGATION active_tab="insights" />
        <GO_BACK />
        {this["state"]["contentful_data"] !== undefined && (
          <>

            <section className="narrow-section padded post">
              <div className="post-content">
                <h1>{this["state"]["contentful_data"]["items"][0]["fields"]["title"]}</h1>
                <p className="posted-on">
                  <b>Posted on:{" "}
                    {dateFormat(
                      this["state"]["contentful_data"]["items"][0]["fields"]["publishDate"],
                      "mmmm d, yyyy"
                    )}
                  </b>
                </p>
                <p className="written-by">
                  <b>Written by:{" "}
                    {this["state"]["contentful_data"]["items"][0]["fields"]["author"]}
                  </b>
                </p>
                {this["state"]["content"].length > 0 &&
                  this["state"]["content"].map((section, index) => {
                    if (
                      section["sys"]["contentType"]["sys"]["id"] ===
                      "contentSection"
                    ) {
                      return (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: render_content_section(
                              this["state"]["content"][index]["fields"]["body"],
                              this["state"]["contentful_data"]["includes"]
                            )
                          }}
                        ></div>
                      );
                    } else {
                      return (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: render_column_section(
                              this["state"]["content"][index]["fields"]["contentSections"],
                              this["state"]["content"][index]["fields"]["desktopWidthPercentages"],
                              this["state"]["contentful_data"]["includes"]
                            )
                          }}
                          className="column-container"
                        ></div>
                      );
                    }
                  })}
              </div>
            </section>
            <div className="related-insights-container">
              <h1 className="section-header">Related Insights</h1>
              <div className="insights related-insights wide-section">
                {this["state"]["insights_data"].length > 0 &&
                  this["state"]["insights_data"].map((insight, index) => (
                    <INSIGHTS_PREVIEW
                      key={index}
                      title={insight["fields"]["title"]}
                      preview={insight["fields"]["previewText"]}
                      image={insight["fields"]["previewImage"]}
                      assets={this["state"]["contentful_data"]["includes"]["Asset"]}
                    />
                  ))}
              </div>
              <Link to="/insights" className="view-all-button">
                <button className="primary-button">View all</button>
              </Link>
            </div>
          </>
        )}
        <FOOTER />
      </>
    );
  }
}


export default Post;

function find_asset_index(id, array) {
  if (array !== undefined) {
    for (let i = 0; i < array.length; i++) {
      if (array[i]["sys"]["id"] === id) {
        return i;
      }
    }
    return false;
  }
}

export default find_asset_index;

import React, { useEffect } from "react";
import NAVIGATION from "components/navigation/navigation";
import FOOTER from "components/footer/footer";
import CONTACT_FORM from "components/contact/contact_form";

function Contact(props) {
  useEffect(() => {
    document.title = "DGG - Contact Us";
  }, []);
  return (
    <>
      <NAVIGATION active_tab="contact" />
      <section className="wide-section contact">
        <h1>Contact Us</h1>
        <CONTACT_FORM />
      </section>
      <FOOTER />
    </>
  );
}

export default Contact;

import React from "react";
import { Link } from "react-router-dom";

function Menu(props) {
  return (
    <>
      <div className="mobile-menu">
        <Link to="/services">
          <div className="tab-container">
            <h3 className={props.active_tab === "services" ? "active" : ""}>Services</h3>
          </div>
        </Link>
        <Link to="/insights">
          <div className="tab-container">
            <h3 className={props.active_tab === "insights" ? "active" : ""}>Insights</h3>
          </div>
        </Link>
        <Link to="/staff">
          <div className="tab-container">
            <h3 className={props.active_tab === "staff" ? "active" : ""}>Team</h3>
          </div>
        </Link>
        <Link to="/contact">
          <div className="tab-container">
            <h3 className={props.active_tab === "contact" ? "active" : ""}>Contact</h3>
          </div>
        </Link>
      </div>
    </>
  );
}

export default Menu;

import React from "react";
import NAVIGATION from "components/navigation/navigation";
import FOOTER from "components/footer/footer";
import GO_BACK from "components/navigation/go_back/go_back";
import find_asset_index from "functions/contentful/find_asset_index";
import render_content_section from "functions/contentful/render_content_section";

export type Props = {
};

export type State = {
  contentful_data?: any,
  content_section_data?: any,
  image_data?: any
};

class Bio extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      contentful_data: undefined,
      content_section_data: undefined,
      image_data: undefined
    };
  }

  componentDidMount() {
    fetch(
      process["env"]["REACT_APP_API_ROOT"] +
      "/contentful/get_staff_profile/" +
      this["props"]["match"]["params"]["name"]
    )
      .then(res => res.json())
      .then(data => {
        document.title = "DGG - " + data["items"][0]["fields"]["fullName"];
        let image_index = find_asset_index(
          data["items"][0]["fields"]["headshot"]["sys"]["id"],
          data["includes"]["Asset"]
        );
        let content_index = find_asset_index(
          data["items"][0]["fields"]["bio"]["sys"]["id"],
          data["includes"]["Entry"]
        )
        this.setState({
          image_data: data["includes"]["Asset"][
            image_index === false ? 0 : image_index
          ],
          contentful_data: data,
          content_section_data: data["includes"]["Entry"][
            content_index === false ? 0 : content_index
          ]
        })
      })
      .catch(console.log);
  }

  render() {
    return (
      <>
        <NAVIGATION active_tab="staff" />
        <GO_BACK />
        <section className="narrow-section padded profile">
          <div className="staff-profile-container">
            {this["state"]["contentful_data"] !== undefined && this["state"]["content_section_data"] !== undefined && (
              <div className="staff-profile-content">
                <div className="staff-profile-image">
                  {this["state"]["image_data"] !== undefined && (
                    <img
                      src={this["state"]["image_data"]["fields"]["file"]["url"]}
                      alt={this["state"]["contentful_data"]["items"][0]["fields"]["fullName"]}
                    />
                  )}
                </div>
                <h1>{this["state"]["contentful_data"]["items"][0]["fields"]["fullName"]}</h1>
                <h4>{this["state"]["contentful_data"]["items"][0]["fields"]["jobTitle"]}</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: render_content_section(
                      this["state"]["content_section_data"]["fields"]["body"],
                      this["state"]["contentful_data"]["includes"]
                    )
                  }}
                ></div>
              </div>
            )}
          </div>
        </section>
        <FOOTER />
      </>
    );
  }
}


export default Bio;

import React from "react";
import arrow_left from "assets/images/arrow-left.svg";
import { withRouter } from 'react-router-dom';
class Go_Back extends React.Component<{}, {}> {

  render() {
    return (
      <>
        <div className="go-back-container" onClick={() => this["props"]["history"].goBack()}>
          <img src={arrow_left} alt="go back" className="arrow-left" />
          <span><b>Back</b></span>
        </div>
      </>
    );
  }
}


export default withRouter(Go_Back);

import React from "react";
import { Link } from "react-router-dom";
import find_asset_index from "functions/contentful/find_asset_index";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";


export type Props = {
  assets?: any,
  content?: any
};

export type State = {
  image_data?: any
};

class Staff_Preview extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      image_data: undefined
    };
  }

  componentDidMount() {
    if (this["props"]["content"] !== undefined) {
      let content_index = find_asset_index(
        this["props"]["content"]["fields"]["headshot"]["sys"]["id"],
        this["props"]["assets"]["Asset"]
      )

      this.setState({
        image_data: this["props"]["assets"]["Asset"][
          content_index === false ? 0 : content_index
        ]
      })
    }
  }

  render() {
    return (
      <>
        <div className="staff-preview-container">
          <div className="staff-preview-image">
            {this["state"]["image_data"] !== undefined && (
              <img
                src={this["state"]["image_data"]["fields"]["file"]["url"]}
                alt={this["props"]["content"]["fields"]["fullName"]}
              />
            )}
          </div>
          <div className="staff-preview-content">
            <h3>{this["props"]["content"]["fields"]["fullName"]}</h3>
            <p>{this["props"]["content"]["fields"]["jobTitle"]}</p>
            <div
              dangerouslySetInnerHTML={{
                __html: documentToHtmlString(this["props"]["content"]["fields"]["blurb"])
              }}
              className="preview-text"
            ></div>
            <div className="cta-button-container">
              <Link
                to={
                  "/staff/" +
                  this["props"]["content"]["fields"]["fullName"]
                    .toLowerCase()
                    .replace(/ /g, "_")
                }
                className="button-link"
              >
                <button className="secondary-button">Read full bio</button>
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }
}


export default Staff_Preview;

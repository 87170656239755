import React from "react";
import { Link } from "react-router-dom";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import find_asset_index from "functions/contentful/find_asset_index";
import logo from "assets/images/dgg-logo.svg";

export type State = {
  contentful_data?: any,
  services_objects?: any
};

class Footer extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      contentful_data: undefined,
      services_objects: []
    };
  }

  componentDidMount() {
    const footerRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ content_type: "footer" })
    };
    fetch(
      process["env"]["REACT_APP_API_ROOT"] + "/contentful",
      footerRequestOptions
    )
      .then(res => res.json())
      .then(data => {
        this.setState({ contentful_data: data })
      })
      .catch(console.log);

    const servicesRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ content_type: "servicesPage" })
    };
    fetch(
      process["env"]["REACT_APP_API_ROOT"] + "/contentful",
      servicesRequestOptions
    )
      .then(res => res.json())
      .then(data => {
        let service_array = [];
        for (
          let i = 0;
          i < data["items"][0]["fields"]["services"].length;
          i++
        ) {
          let service_index = find_asset_index(
            data["items"][0]["fields"]["services"][i]["sys"]["id"],
            data["includes"]["Entry"]
          )
          let service_object =
            data["includes"]["Entry"][
            service_index === false ? 0 : service_index
            ];
          service_array.push(service_object);
        }
        this.setState({ services_objects: service_array })
      })
      .catch(console.log);

  }

  render() {
    return (
      <>
        <div className="footer">
          {this["state"]["contentful_data"] !== undefined && (
            <>
              <div className="desktop">
                <div className="legal-footer-container">
                  <Link to="/">
                    <img
                      src={logo}
                      className="dgg-logo"
                      alt="Digital Gaming Group logo"
                    />
                  </Link>
                  <div>
                    <h3>{(new Date().getFullYear())} Digital Gaming Group LLC</h3>
                    <p>All Rights Reserved</p>
                  </div>
                </div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: documentToHtmlString(
                      this["state"]["contentful_data"]["items"][0]["fields"]["contactText"]
                    )
                  }}
                  className="contact-footer-container"
                ></div>
                <div className="services-footer-container">
                  <Link to="/services">
                    <h4>Services</h4>
                  </Link>
                  {this["state"]["services_objects"].length > 0 &&
                    this["state"]["services_objects"].map((service, index) => (
                      <div

                        key={index}
                      >
                        <p>{service["fields"]["title"]}</p>
                      </div>
                    ))}
                </div>
                <div className="tabs-footer-container">
                  <Link to="/insights">
                    <p>Insights</p>
                  </Link>
                  <Link to="/staff">
                    <p>Team</p>
                  </Link>
                  <Link to="/contact">
                    <p>Contact</p>
                  </Link>
                </div>
              </div>

              <div className="mobile">
                <div className="image-footer-container">
                  <img
                    src={logo}
                    className="dgg-logo"
                    alt="Digital Gaming Group logo"
                  />
                </div>
                <div className="contact-footer-container">
                  <div>
                    <h3>{(new Date().getFullYear())} Digital Gaming Group LLC</h3>
                    <p>All Rights Reserved</p>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: documentToHtmlString(
                        this["state"]["contentful_data"]["items"][0]["fields"]["contactText"]
                      )
                    }}

                  ></div>
                </div>
              </div>
            </>
          )}
        </div>
      </>
    );
  }
}


export default Footer;

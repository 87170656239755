import React from "react";
import ReactDOMServer from "react-dom/server";
import find_asset_index from "functions/contentful/find_asset_index";
import {documentToHtmlString} from "@contentful/rich-text-html-renderer";

function render_content_section(content_section_array, assets) {
  if (content_section_array !== undefined) {
    let post_content = "";
    for (let i = 0; i < content_section_array.length; i++) {
      let node =
        assets["Entry"][
          find_asset_index(
            content_section_array[i]["sys"]["id"],
            assets["Entry"]
          )
        ];
      if (node !== undefined) {
        if (node["fields"]["body"] !== undefined) {
          let text_content = documentToHtmlString(node["fields"]["body"]);
          post_content += text_content;
        } else {
          let image_object =
            assets["Asset"][
              find_asset_index(
                node["fields"]["file"]["sys"]["id"],
                assets["Asset"]
              )
            ];

          post_content += ReactDOMServer.renderToStaticMarkup(
            <img
              src={image_object["fields"]["file"]["url"]}
              alt={image_object["fields"]["title"]}
              key={i}
            />
          );
        }
      }
    }
    return post_content;
  }
}

export default render_content_section;

import React from "react";
import NAVIGATION from "components/navigation/navigation";
import FOOTER from "components/footer/footer";
import BANNER from "components/banner/banner";
import INSIGHT from "views/insights/components/insight";
import INSIGHTS_PREVIEW from "components/insights/insights_preview";
import find_asset_index from "functions/contentful/find_asset_index";


export type State = {
  contentful_data?: any,
  insights_data?: any
};

class Insights extends React.Component<{}, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      contentful_data: undefined,
      insights_data: []
    };
  }

  componentDidMount() {
    document.title = "DGG - Insights";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ content_type: "insightsPage" })
    };
    fetch(process["env"]["REACT_APP_API_ROOT"] + "/contentful", requestOptions)
      .then(res => res.json())
      .then(data => {
        this.setState({ contentful_data: data })
        let insight_array = [];
        for (
          let i = 0;
          i < data["items"][0]["fields"]["insights"].length;
          i++
        ) {
          let insight_index = find_asset_index(
            data["items"][0]["fields"]["insights"][i]["sys"]["id"],
            data["includes"]["Entry"]
          )
          insight_array.push(
            data["includes"]["Entry"][
            insight_index === false ? 0 : insight_index
            ]
          );
        }
        this.setState({ insights_data: insight_array })
      })
      .catch(console.log);
  }

  render() {
    return (
      <>
        <NAVIGATION active_tab="insights" />
        <BANNER
          image={
            this["state"]["contentful_data"] === undefined
              ? ""
              : this["state"]["contentful_data"]["items"][0]["fields"]["bannerImage"]
          }
          assets={
            this["state"]["contentful_data"] === undefined ? {} : this["state"]["contentful_data"]["includes"]
          }
        />
        <section className="wide-section insights-page">
          <h1>Insights</h1>
          <div className="insights">
            {this["state"]["insights_data"].length > 0 &&
              this["state"]["insights_data"].map((insight, index) => (
                <>
                  <INSIGHT
                    preview={insight}
                    assets={this["state"]["contentful_data"]["includes"]["Asset"]}
                    image_first={index % 2 === 0 ? true : false}
                  />
                  <INSIGHTS_PREVIEW
                    title={insight["fields"]["title"]}
                    preview={insight["fields"]["previewText"]}
                    image={insight["fields"]["previewImage"]}
                    assets={this["state"]["contentful_data"]["includes"]["Asset"]}
                  />
                </>
              ))}
          </div>
        </section>
        <FOOTER />
      </>
    );
  }
}


export default Insights;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import MENU from "components/navigation/mobile/menu";
import hamburger from "assets/images/hamburger_button.svg";
import x from "assets/images/x_button.svg";
import logo from "assets/images/dgg-logo.svg";


function Mobile(props) {
  const [menu_open, set_menu_open] = useState(false);

  return (
    <>
      <div
        className={"mobile-navbar " + (menu_open ? "expanded" : "")}
        style={{ opacity: "100%" }}
      >
        <div className="mobile-navbar-container">
          <Link to="/">
            <img
              src={logo}
              className="dgg-logo"
              alt="Digital Gaming Group logo"
            />
          </Link>
          <div className="transforming-button">
            {menu_open && (
              <div className="mobile-x-button">
                <img
                  src={x}
                  alt="close menu"
                  onClick={() => set_menu_open(!menu_open)}
                />
              </div>
            )}
            {!menu_open && (
              <div className="mobile-hamburger-button">
                <img
                  src={hamburger}
                  alt="open menu"
                  onClick={() => set_menu_open(!menu_open)}
                />
              </div>
            )}
          </div>
        </div>
        <MENU active_tab={props.active_tab} />
      </div>
    </>
  );
}

export default Mobile;

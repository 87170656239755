import React from "react";
import MOBILE from "components/navigation/mobile/mobile";
import DESKTOP from "components/navigation/desktop/desktop";

function Navigation(props) {
  return (
    <>
      <MOBILE active_tab={props.active_tab} />
      <DESKTOP active_tab={props.active_tab} />
    </>
  );
}

export default Navigation;

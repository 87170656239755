import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Bio from "./views/bio/Bio";
import Contact from "views/contact/Contact";
import Home from "views/home/Home";
import Insights from "views/insights/Insights";
import Post from "views/post/Post";
import Services from "views/services/Services";
import Staff from "views/staff/Staff";
import "styles/dgg.scss";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/services" render={props => <Services {...props} />} />
      <Route path="/insights/:name" render={props => <Post {...props} />} />
      <Route path="/insights" render={props => <Insights {...props} />} />
      <Route path="/contact" render={props => <Contact {...props} />} />
      <Route path="/staff/:name" render={props => <Bio {...props} />} />
      <Route path="/staff" render={props => <Staff {...props} />} />
      <Route path="/" render={props => <Home {...props} />} />
      <Route path="" render={props => <Home {...props} />} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

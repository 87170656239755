import React from "react";
import find_asset_index from "functions/contentful/find_asset_index";
import render_content_section from "functions/contentful/render_content_section";
import render_column_section from "functions/contentful/render_column_section";


export type Props = {
  assets?: any,
  body?: any
};

export type State = {
  content?: any
};

class About_Us_Section extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      content: []
    };
  }

  componentDidMount() {
    this.get_about_us_content();
  }

  get_about_us_content() {
    if (
      this["props"]["body"] !== undefined &&
      this["props"]["body"].length > 0 &&
      this["props"]["assets"] !== undefined
    ) {
      let content_array = [];
      for (let i = 0; i < this["props"]["body"].length; i++) {
        let content_index = find_asset_index(
          this["props"]["body"][i]["sys"]["id"],
          this["props"]["assets"]["Entry"]
        );
        content_array.push(
          this["props"]["assets"]["Entry"][
          content_index === false ? 0 : content_index
          ]
        );
      }
      this.setState({ content: content_array })
    }
  }

  render() {
    return (
      <>
        <div className="about-us-container">
          {this["state"]["content"].length > 0 &&
            this["state"]["content"].map((section, index) => {
              if (
                section["sys"]["contentType"]["sys"]["id"] === "contentSection"
              ) {
                return (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: render_content_section(
                        this["state"]["content"][index]["fields"]["body"],
                        this["props"]["assets"]
                      )
                    }}
                    key={index}
                  ></div>
                );
              } else {
                return (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: render_column_section(
                        this["state"]["content"][index]["fields"]["contentSections"],
                        this["state"]["content"][index]["fields"]["desktopWidthPercentages"],
                        this["props"]["assets"]
                      )
                    }}
                    key={index}
                    className="column-container "
                  ></div>
                );
              }
            })}
        </div>
      </>
    );
  }
}


export default About_Us_Section;
